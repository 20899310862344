import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// Assets
import LogoManekawa from "../../assets/svg/LogoManekawa";
import theme from "../../theme";


export default function Contact({ isLp = false }) {
  const { t } = useTranslation();

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <div className="darkBg">
      {isLp && <div className="p-4 text-right grid-col-1  text-ltsWhite">
        <p className="font10">{t('Footer--footnote-1')}</p>
        <p className="font10">{t('Footer--footnote-2')}</p>
        <p className="font10">{t('Footer--footnote-3')}</p>
      </div>}
      <div className="container">
        <div className="pb-8 flex items-center content-center justify-around max-md:flex-col">
          <RouterLink className="animate pointer max-md:mb-5" to="/" reloadDocument>
            <LogoManekawa width="200px" fillColor={theme.colors.white}/>
          </RouterLink>
          <span className="whiteColor font13 max-md:mb-5">
            © {getCurrentYear()} - {t('General--company-name')} All Rights Reserved.
          </span>
          <RouterLink className="pointer max-md:mb-5" to="/privacy" reloadDocument>
            <span className="whiteColor font13 underline">
              プライバシーポリシー
            </span>
          </RouterLink>
          <RouterLink className="pointer max-md:mb-5" to="/spca" reloadDocument>
            <span className="whiteColor font13 underline">
              特定商取引法に基づく表記
            </span>
          </RouterLink>
          {isLp && <Link className="whiteColor animate pointer font13 underline" to="home" smooth={true} offset={-80}>
            Back to top
          </Link>}
        </div>
      </div>
    </div>
  );
}