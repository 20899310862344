import styled from "styled-components";
import theme from "../../theme";

export default function FullButton({ className, title, action, border }) {
  return (
    <Wrapper
      className={`${className ?? ""} animate pointer radius8 buttonText flex items-center justify-center`}
      onClick={action ? () => action() : null}
      border={border}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? theme.colors.gray : theme.colors.main)};
  background-color: ${(props) => (props.border ? "transparent" : theme.colors.main)};
  width: 100%;
  padding: 1rem;
  outline: none;
  color: ${(props) => (props.border ? theme.colors : theme.colors.white)};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#646df3")};
    border: 1px solid #646df3;
    color: ${(props) => (props.border ? "#646df3" : theme.colors.white)};
  }
`;

