import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from "react-router-dom";
// Assets
import JavaTextImage from "../../assets/img/projects/java-text-image.png";
// Hooks
import useCheckoutHandler from "../../hooks/useCheckoutHandler";


export default function Projects() {
  const { t } = useTranslation();
  const handleCheckout = useCheckoutHandler();

  return (
    <Wrapper id="projects">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner className="flexColumn flexCenter">
                <ImgWrapper>
                  <img className="radius8" src={JavaTextImage} alt="Java training textbook" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">{t('Projects--pre-header')}</h4>
              <h2 className="font40 extraBold py-4">{t('Projects--header')}</h2>
              <p className="font13">{t('Projects--description-1')}<br /></p>
              <div className="grid-cols-1">
                <h3 className="font20 semiBold pt-10">
                  {t('Projects--details-header')}
                  </h3>
                <div className="grid-cols-2 gap-2">
                  <p className="font15 mb-2">{t('Projects--details-1')}</p>
                  <p className="font15 mb-2">{t('Projects--details-2')}</p>
                  <p className="font15 mb-2">{t('Projects--details-3')}</p>
                  <p className="font15 mb-2">{t('Projects--details-4')}</p>
                  <p className="font15 mb-2">{t('Projects--details-5')}</p>
                  <p className="font15 mb-2">{t('Projects--details-6')}</p>
                </div>
              </div>
              <ButtonsRow className="w-full my-8">
                <button className="w-full sm:w-4/5 semiBold font15 pointer flexCenter bg-ltsBlue p-4 rounded-xl text-ltsWhite" onClick={handleCheckout}>
                  {t('General--cta')}
                </button>
              </ButtonsRow>
              <ButtonsRow className="w-full my-8">
                <RouterLink to="/sample-thanks" reloadDocument>
                  <span className="w-full sm:w-4/5 semiBold font15 pointer flexCenter bg-ltsOrange p-4 rounded-xl text-ltsWhite">
                    {t('Projects--sample-cta')}
                  </span>
                </RouterLink>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h2, h4 {text-align: center;}
`;
const Advertising = styled.div`
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
    margin: 1rem 0;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 30rem;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
    margin-bottom: -1rem;
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    margin-top: -1rem;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  max-width: 70vw;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
`;
