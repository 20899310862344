import ReactDOM from 'react-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';
import App from './App';
import { ThemeProvider } from 'styled-components';
import theme from './theme';

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </I18nextProvider>
,
  document.getElementById('root')
);
