import styled from "styled-components";
import { useTranslation } from 'react-i18next';
// Components
// Assets
import MasaImage from "../../assets/img/masa.png";

export default function Profile() {
  const { t } = useTranslation();

  return (
    <Wrapper id="profile">
      <div className="whiteBg">
        <div className="container lg:max-w-[90vw]">
          <Advertising className="flex justify-around items-center md:justify-center md:content-center ">
            <LeftText className="lg:max-w-[40rem]">
              <h2 className="font40 extraBold pb-8">
                {t('Profile--header')}
              </h2>
              <div className="grid-cols-1">
                <div className="grid-cols-2 gap-2">
                  <p className="font-bold">{t('Profile--title')}</p>
                  <p>{t('Profile--name')}</p>
                </div>
                <div className="grid-cols-2 gap-2">
                  <p className="font-bold">{t('Profile--history-header')}</p>
                  <p>{t('Profile--history')}</p>
                </div>
              </div>
            </LeftText>
            <RightImage className="lg:max-w-[30rem]">
              <RightInner>
                <ImgWrapper className="flexCenter">
                  <img className="w-screen sm:max-w-[50vw]" src={MasaImage} alt="Masayuki Anekawa, representative for Manekawa" />
                </ImgWrapper>
              </RightInner>
            </RightImage>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h2, h4 {text-align: center;}
`;
const Advertising = styled.div`
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const RightImage = styled.div`
  position: relative;
  width: 45%;
  p {
    max-width: 30rem;
  }
  @media (max-width: 860px) {
    width: 100%;
    order: 1;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const LeftText = styled.div`
  width: 55%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const RightInner = styled.div`
  width: 100%;
  position: absolute;
  top: -20rem;
  left: 0;
  @media (max-width: 1190px) {
    top: -18rem;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -3rem;
    left: 0;
  }
  @media (max-width: 480px) {
    order: 1;
    position: relative;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: 50% 15%;
  }
  @media (max-width: 860px) {
    img {
      height: 60vw;
    }
  }
  @media (max-width: 480px) {
    img {
      height: 80vw;
    }
  }
`;
