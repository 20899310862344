import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './assets/i18n/en';
import ja from './assets/i18n/ja';

i18next
  .use(initReactI18next)
  .init({
    lng: 'ja',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en
      },
      ja: {
        translation: ja
      },
    }
  });

export default i18next;
