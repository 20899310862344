import styled from "styled-components";

export default function ServiceBox({image, imageAlt, title, description}) {
  return (
    <div className="w-full flexNullCenter flexColumn">
      <img className="radius8 w-full sm:max-w-[50vw]" src={image} alt={imageAlt} />
      <TitleStyle className="font20 extraBold sm:max-w-[50vw]">{title}</TitleStyle>
      <Description className="font13 sm:max-w-[50vw]">{description}</Description>
    </div>
  );
}

const TitleStyle = styled.h2`
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const Description = styled.p`
  width: 100%;
  margin: 0 auto;

  @media (max-width: 860px) {
    text-align: left;
  }
`;