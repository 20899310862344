import { useTheme } from "styled-components";

const LogoManekawa = ({ width, fillColor }) => {
    const theme = useTheme();
    const mainColor = fillColor ?? theme.colors.main;
    const mainStyle = {
        fill: mainColor,
        fillRule: "nonzero",
    };

    return (
        <svg width={width ?? "100%"} viewBox="0 0 1246 263" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
            <g transform="matrix(1,0,0,1,-544.371,-2070.59)">
                <title>マネカワ: 外資系トップエンジニアが教えるプログラミング教育サービス</title>
                <desc>マネカワのロゴ</desc>
                <g>
                    <g transform="matrix(0.347476,0,0,0.347476,230.859,1793.99)">
                        <g transform="matrix(2.8779,0,0,2.8779,-664.388,-5162.92)">
                            <path d="M633.268,2172.18L633.268,2172.18L665.848,2172.18L665.848,2183.52C674.728,2175 684.688,2170.74 695.728,2170.74C709.408,2170.74 719.848,2176.5 727.048,2188.02C734.368,2176.38 744.808,2170.56 758.368,2170.56C762.688,2170.56 766.768,2171.19 770.608,2172.45C774.448,2173.71 777.748,2175.69 780.508,2178.39C783.268,2181.09 785.458,2184.6 787.078,2188.92C788.698,2193.24 789.508,2198.46 789.508,2204.58L789.508,2264.52L756.928,2264.52L756.928,2218.98C756.928,2214.3 756.598,2210.46 755.938,2207.46C755.278,2204.46 754.318,2202.12 753.058,2200.44C751.798,2198.76 750.328,2197.62 748.648,2197.02C746.968,2196.42 745.168,2196.12 743.248,2196.12C732.808,2196.12 727.588,2203.74 727.588,2218.98L727.588,2264.52L695.008,2264.52L695.008,2218.98C695.008,2214.3 694.708,2210.43 694.108,2207.37C693.508,2204.31 692.638,2201.88 691.498,2200.08C690.358,2198.28 688.888,2197.05 687.088,2196.39C685.288,2195.73 683.188,2195.4 680.788,2195.4C678.748,2195.4 676.828,2195.7 675.028,2196.3C673.228,2196.9 671.638,2198.07 670.258,2199.81C668.878,2201.55 667.798,2203.95 667.018,2207.01C666.238,2210.07 665.848,2214.06 665.848,2218.98L665.848,2264.52L633.268,2264.52L633.268,2186.13L625.211,2172.18L633.268,2172.18Z" style={mainStyle} />
                        </g>
                        <g transform="matrix(-0.99853,-1.22285e-16,1.22465e-16,-1,5028.32,2440.19)">
                            <path d="M1158.09,1088.39L1251.85,1088.39L1251.85,1121.03C1277.41,1096.51 1306.07,1084.25 1337.84,1084.25C1377.21,1084.25 1407.26,1100.82 1427.98,1133.98C1449.05,1100.48 1479.09,1083.73 1518.12,1083.73C1530.55,1083.73 1542.29,1085.54 1553.34,1089.17C1564.39,1092.79 1573.89,1098.49 1581.83,1106.26C1589.78,1114.03 1596.08,1124.13 1600.74,1136.57C1605.4,1149 1607.73,1164.02 1607.73,1181.63L1607.73,1354.13L1513.97,1354.13L1513.97,1223.08C1513.97,1209.61 1513.02,1198.56 1511.12,1189.92C1509.22,1181.29 1506.46,1174.55 1502.83,1169.72C1499.21,1164.88 1494.98,1161.6 1490.14,1159.88C1485.31,1158.15 1480.13,1157.29 1474.6,1157.29C1444.56,1157.29 1429.53,1179.22 1429.53,1223.08L1429.53,1354.13L1335.77,1354.13L1335.77,1223.08C1335.77,1209.61 1334.91,1198.47 1333.18,1189.66C1331.45,1180.86 1328.95,1173.86 1325.67,1168.68C1322.39,1163.5 1318.16,1159.96 1312.98,1158.06C1307.8,1156.16 1301.76,1155.21 1294.85,1155.21C1288.98,1155.21 1283.45,1156.08 1278.27,1157.8C1273.09,1159.53 1268.52,1162.9 1264.54,1167.91C1260.57,1172.91 1257.46,1179.82 1255.22,1188.63C1252.98,1197.43 1251.85,1208.92 1251.85,1223.08L1251.85,1354.13L1158.09,1354.13L1158.09,1088.39Z" style={mainStyle} />
                        </g>
                        <path d="M1759,1220.49C1759,1229.12 1760.55,1237.15 1763.66,1244.57C1766.77,1252 1771,1258.47 1776.35,1264C1781.7,1269.52 1788.09,1273.84 1795.52,1276.95C1802.94,1280.06 1810.97,1281.61 1819.6,1281.61C1827.89,1281.61 1835.75,1280.06 1843.17,1276.95C1850.6,1273.84 1856.99,1269.52 1862.34,1264C1867.69,1258.47 1872.01,1252.08 1875.29,1244.83C1878.57,1237.58 1880.21,1229.81 1880.21,1221.52C1880.21,1213.23 1878.57,1205.38 1875.29,1197.95C1872.01,1190.53 1867.69,1184.05 1862.34,1178.53C1856.99,1173 1850.6,1168.68 1843.17,1165.58C1835.75,1162.47 1827.89,1160.91 1819.6,1160.91C1810.97,1160.91 1802.94,1162.47 1795.52,1165.58C1788.09,1168.68 1781.7,1173 1776.35,1178.53C1771,1184.05 1766.77,1190.35 1763.66,1197.43C1760.55,1204.51 1759,1212.2 1759,1220.49ZM1877.1,1088.39L1971.38,1088.39L1971.38,1354.13L1877.1,1354.13L1877.1,1324.61C1857.07,1349.82 1829.96,1362.42 1795.78,1362.42C1776.44,1362.42 1758.65,1358.88 1742.42,1351.8C1726.19,1344.72 1712.03,1334.8 1699.94,1322.02C1687.85,1309.24 1678.44,1294.22 1671.71,1276.95C1664.98,1259.68 1661.61,1240.86 1661.61,1220.49C1661.61,1201.49 1664.89,1183.45 1671.45,1166.35C1678.01,1149.26 1687.16,1134.32 1698.9,1121.54C1710.65,1108.77 1724.63,1098.66 1740.87,1091.24C1757.1,1083.81 1775.05,1080.1 1794.74,1080.1C1827.89,1080.1 1855.35,1091.67 1877.1,1114.81L1877.1,1088.39Z" style={mainStyle} />
                        <path d="M2035.1,1088.39L2128.86,1088.39L2128.86,1122.06C2141.64,1106.52 2154.59,1096.07 2167.71,1090.72C2180.84,1085.37 2196.21,1082.69 2213.82,1082.69C2232.47,1082.69 2248.44,1085.71 2261.74,1091.76C2275.03,1097.8 2286.34,1106.35 2295.67,1117.4C2303.26,1126.38 2308.44,1136.39 2311.21,1147.44C2313.97,1158.5 2315.35,1171.1 2315.35,1185.26L2315.35,1354.13L2221.59,1354.13L2221.59,1219.97C2221.59,1206.84 2220.64,1196.22 2218.74,1188.11C2216.84,1179.99 2213.47,1173.52 2208.64,1168.68C2204.49,1164.54 2199.83,1161.6 2194.65,1159.88C2189.47,1158.15 2183.95,1157.29 2178.07,1157.29C2162.19,1157.29 2150.01,1162.04 2141.55,1171.53C2133.09,1181.03 2128.86,1194.58 2128.86,1212.2L2128.86,1354.13L2035.1,1354.13L2035.1,1088.39Z" style={mainStyle} />
                        <path d="M2573.33,1182.67C2570.22,1169.55 2563.83,1159.01 2554.16,1151.07C2544.49,1143.13 2532.75,1139.16 2518.93,1139.16C2504.43,1139.16 2492.6,1142.95 2483.45,1150.55C2474.3,1158.15 2468.51,1168.86 2466.1,1182.67L2573.33,1182.67ZM2463.51,1237.06C2463.51,1277.47 2482.5,1297.67 2520.49,1297.67C2540.86,1297.67 2556.23,1289.38 2566.59,1272.81L2657.24,1272.81C2638.94,1333.59 2593.18,1363.98 2519.97,1363.98C2497.52,1363.98 2476.97,1360.61 2458.32,1353.88C2439.68,1347.14 2423.7,1337.56 2410.41,1325.12C2397.11,1312.69 2386.84,1297.84 2379.59,1280.58C2372.33,1263.31 2368.71,1243.97 2368.71,1222.56C2368.71,1200.45 2372.16,1180.51 2379.07,1162.73C2385.97,1144.94 2395.82,1129.83 2408.59,1117.4C2421.37,1104.97 2436.83,1095.38 2454.96,1088.65C2473.09,1081.91 2493.55,1078.55 2516.34,1078.55C2538.79,1078.55 2558.99,1081.91 2576.95,1088.65C2594.91,1095.38 2610.11,1105.14 2622.54,1117.92C2634.97,1130.69 2644.47,1146.32 2651.03,1164.8C2657.59,1183.27 2660.87,1204.08 2660.87,1227.22L2660.87,1237.06L2463.51,1237.06Z" style={mainStyle} />
                        <path d="M2807.99,924.177L2807.99,1184.22L2906.41,1088.39L3034.88,1088.39L2901.75,1211.16L3042.65,1354.13L2911.08,1354.13L2807.99,1245.87L2807.99,1354.13L2714.23,1354.13L2714.23,924.177L2807.99,924.177Z" style={mainStyle} />
                        <path d="M3146.78,1220.49C3146.78,1229.12 3148.33,1237.15 3151.44,1244.57C3154.55,1252 3158.78,1258.47 3164.13,1264C3169.48,1269.52 3175.87,1273.84 3183.3,1276.95C3190.72,1280.06 3198.75,1281.61 3207.38,1281.61C3215.67,1281.61 3223.53,1280.06 3230.95,1276.95C3238.38,1273.84 3244.77,1269.52 3250.12,1264C3255.47,1258.47 3259.79,1252.08 3263.07,1244.83C3266.35,1237.58 3267.99,1229.81 3267.99,1221.52C3267.99,1213.23 3266.35,1205.38 3263.07,1197.95C3259.79,1190.53 3255.47,1184.05 3250.12,1178.53C3244.77,1173 3238.38,1168.68 3230.95,1165.58C3223.53,1162.47 3215.67,1160.91 3207.38,1160.91C3198.75,1160.91 3190.72,1162.47 3183.3,1165.58C3175.87,1168.68 3169.48,1173 3164.13,1178.53C3158.78,1184.05 3154.55,1190.35 3151.44,1197.43C3148.33,1204.51 3146.78,1212.2 3146.78,1220.49ZM3264.88,1088.39L3359.16,1088.39L3359.16,1354.13L3264.88,1354.13L3264.88,1324.61C3244.85,1349.82 3217.74,1362.42 3183.56,1362.42C3164.22,1362.42 3146.43,1358.88 3130.2,1351.8C3113.97,1344.72 3099.81,1334.8 3087.72,1322.02C3075.63,1309.24 3066.22,1294.22 3059.49,1276.95C3052.75,1259.68 3049.39,1240.86 3049.39,1220.49C3049.39,1201.49 3052.67,1183.45 3059.23,1166.35C3065.79,1149.26 3074.94,1134.32 3086.68,1121.54C3098.43,1108.77 3112.41,1098.66 3128.64,1091.24C3144.88,1083.81 3162.83,1080.1 3182.52,1080.1C3215.67,1080.1 3243.13,1091.67 3264.88,1114.81L3264.88,1088.39Z" style={mainStyle} />
                        <path d="M4033.11,1220.49C4033.11,1229.12 4034.66,1237.15 4037.77,1244.57C4040.88,1252 4045.11,1258.47 4050.46,1264C4055.82,1269.52 4062.2,1273.84 4069.63,1276.95C4077.06,1280.06 4085.09,1281.61 4093.72,1281.61C4102.01,1281.61 4109.86,1280.06 4117.29,1276.95C4124.71,1273.84 4131.1,1269.52 4136.45,1264C4141.81,1258.47 4146.12,1252.08 4149.41,1244.83C4152.69,1237.58 4154.33,1229.81 4154.33,1221.52C4154.33,1213.23 4152.69,1205.38 4149.41,1197.95C4146.12,1190.53 4141.81,1184.05 4136.45,1178.53C4131.1,1173 4124.71,1168.68 4117.29,1165.58C4109.86,1162.47 4102.01,1160.91 4093.72,1160.91C4085.09,1160.91 4077.06,1162.47 4069.63,1165.58C4062.2,1168.68 4055.82,1173 4050.46,1178.53C4045.11,1184.05 4040.88,1190.35 4037.77,1197.43C4034.66,1204.51 4033.11,1212.2 4033.11,1220.49ZM4151.22,1088.39L4245.5,1088.39L4245.5,1354.13L4151.22,1354.13L4151.22,1324.61C4131.19,1349.82 4104.08,1362.42 4069.89,1362.42C4050.55,1362.42 4032.76,1358.88 4016.53,1351.8C4000.3,1344.72 3986.14,1334.8 3974.06,1322.02C3961.97,1309.24 3952.56,1294.22 3945.82,1276.95C3939.09,1259.68 3935.72,1240.86 3935.72,1220.49C3935.72,1201.49 3939,1183.45 3945.56,1166.35C3952.13,1149.26 3961.28,1134.32 3973.02,1121.54C3984.76,1108.77 3998.75,1098.66 4014.98,1091.24C4031.21,1083.81 4049.17,1080.1 4068.85,1080.1C4102.01,1080.1 4129.46,1091.67 4151.22,1114.81L4151.22,1088.39Z" style={mainStyle} />
                    </g>
                    <g transform="matrix(1.004,0,0,1.02488,-52.6388,-83.068)">
                        <rect x="594.629" y="2101.37" width="1240.34" height="256.512" fill="none" />
                    </g>
                </g>
            </g>
        </svg>);
};

export default LogoManekawa;
