import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { Link } from "react-scroll";

// Components
import ServiceBox from "../Elements/ServiceBox";
// Assets
import OfferTalkImage from "../../assets/img/campaign/offer-talk.jpg";
import OfferWebImage from "../../assets/img/campaign/offer-web.jpg";
import OfferUpdateImage from "../../assets/img/campaign/offer-update.jpg";

export default function Campaign() {
  const { t } = useTranslation();

  return (
    <Wrapper id="services">
      <div className="orangeBg text-ltsWhite pt-4 pb-4  items-center">
        <div className="container">
          <HeaderInfo>
            <h2 className="font40 extraBold">{t('Campaign--header')}</h2>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                image={OfferTalkImage}
                imageAlt={t('Campaign--offer-medal-image-alt')}
                title={t('Campaign--offer-1')}
                description={t('Campaign--offer-1-description')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={OfferWebImage}
                imageAlt={t('Campaign--offer-medal-image-alt')}
                title={t('Campaign--offer-2')}
                description={t('Campaign--offer-2-description')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={OfferUpdateImage}
                imageAlt={t('Campaign--offer-medal-image-alt')}
                title={t('Campaign--offer-3')}
                description={t('Campaign--offer-3-description')}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="max-w-sm mx-auto pb-4">
          <Link to="projects" smooth={true} offset={-130}>
            <ButtonInput type="button" value={t('Header--cta')} className="pointer animate radius8 font15 font-bold" />
          </Link>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  justify-content: space-around;
`;
const ServiceBoxWrapper = styled.div`
  width: 25%;
  padding: 0 0 2rem;
  @media (max-width: 860px) {
    width: 100%;
    padding: 0 0 2.5rem;
  }
`;
const HeaderInfo = styled.div`
  h2 {
    padding: 1rem 0;
  }
`;
const ButtonInput = styled.input`
  width: 80%;
  border: 1px solid ${props => props.theme.colors.main};
  background-color: ${props => props.theme.colors.main};
  padding: 1rem;
  outline: none;
  color: #fff;
  overflow-wrap: break-word;
  :hover {
    background-color: ${props => props.theme.colors.mainLight};
    border: 1px solid ${props => props.theme.colors.mainLight};
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
    height: 4rem;
    width: 100%;
  }
`;