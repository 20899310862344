import { useTranslation } from "react-i18next";
import styled from "styled-components";

import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"
import useDownloadSampleEffect from "../hooks/useDownloadSampleEffect";

const SampleThanks = () => {
  const { t } = useTranslation();
  useDownloadSampleEffect();

  return (
    <>
      <TopNavbar isLp={false} />
      <Wrapper id="home" className="container flexSpaceCenter flexColumn">
        <LeftSide className="flex flex-col items-center">
            <h1 className="pt-10 text-4xl max-sm:p-0 max-sm:text-2xl font-extrabold text-ltsBlue">{t('SampleThanks--header')}</h1>
            <HeaderP className="font15 semiBold">
              {t('SampleThanks--description')}
            </HeaderP>
        </LeftSide>
      </Wrapper>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 100px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  height: 100%;
  @media (max-width: 960px) {
    width: 90%;
    order: 2;
    margin: 50px 0;
    h1 { text-align: center; }
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    max-width: 100%;
  }
`;

export default SampleThanks;