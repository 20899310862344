import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Components
import FullButton from "../Buttons/FullButton";
// Assets
import LogoManekawa from "../../assets/svg/LogoManekawa";

export default function TopNavbar({ isLp = false }) {
  const [y, setY] = useState(window.scrollY);
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
        <NavInner className="container flexSpaceCenter">
          <RouterLink className="pointer flexNullCenter" to="/" reloadDocument >
            {isLp ?
              <h1><LogoManekawa width="200px" /></h1> :
              <LogoManekawa width="200px" />}

          </RouterLink>
          {isLp && (
            <>
              <UlWrapper className="flexNullCenter">
                <li className="semiBold font15 pointer">
                  <Link activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={true} offset={-80}>
                    {t('TopNavBar--home')}
                  </Link>
                </li>
                <li className="semiBold font15 pointer">
                  <Link activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={true} offset={-80}>
                    {t('TopNavBar--services')}
                  </Link>
                </li>
                <li className="semiBold font15 pointer">
                  <Link activeClass="active" style={{ padding: "10px 15px" }} to="projects" spy={true} smooth={true} offset={-80}>
                    {t('TopNavBar--projects')}
                  </Link>
                </li>
              </UlWrapper>
              <div className="flexCenter">
                <li className="semiBold font15 pointer flexCenter">
                  <Link to="projects" smooth={true} offset={-130}>
                      <ThinFullButton title={t("TopNavBar--cta")} />
                    </Link>
                </li>
              </div>
            </>
          )}
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const ThinFullButton = styled(FullButton)`
  padding: .5rem 1rem;
`;

