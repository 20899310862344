import Footer from "../components/Sections/Footer";
import TopNavbar from "../components/Nav/TopNavbar";

interface dataType {
  header: string;
  data: string;
}

const data: dataType[] = [
  {
    header: "販売業者名",
    data: "姉川将之",
  },
  {
    header: "販売責任者",
    data: "姉川将之",
  },
  {
    header: "所在地",
    data: "東京都豊島区高田2-16-13",
  },
  {
    header: "電話番号",
    data: "080-5374-5402（受付時間 月曜日～金曜日 午前10時～午後5時まで ※土日、祝日はお休み）",
  },
  {
    header: "メールアドレス",
    data: "contact@learntosurvive.dev",
  },
  {
    header: "支払方法",
    data: "クレジットカード",
  },
  {
    header: "商品引渡し時期",
    data: "決済完了後、24時間以内にメールにてご案内",
  },
  {
    header: "商品以外の必要料金",
    data: "なし",
  },
  {
    header: "返品・キャンセルについて",
    data: "キャンセルを希望される場合は、お申込みから3日以内に電話またはメールアドレスよりお問い合わせをお願いいたします。",
  },
];

function Spca() {
  return (
    <>
      <TopNavbar />
      <div className="px-5">
        <h1 className="mt-24 mb-5 text-3xl max-sm:text-2xl text-center text-ltsBlue font-bold">特定商取引法に基づく表記</h1>
        <div className="mb-5 w-5/6 max-w-2xl mx-auto grid grid-cols-1">
          {data.map((item, i) => (
            <div key={i} className="grid grid-cols-2 max-sm:grid-cols-1 gap-2 mb-8">
              <p className="font-bold">{item.header}</p>
              <p>{item.data}</p>
            </div>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Spca;
