const ja = {
  // General
  "General--company-name": "マネカワ",
  "General--cta": "購入へ進む",
  "General--privacy-policy": "プライバシーポリシー",

  // TopNavBar
  "TopNavBar--home": "ホーム",
  "TopNavBar--services": "サービス",
  "TopNavBar--projects": "テキスト",
  "TopNavBar--blog": "ブログ",
  "TopNavBar--pricing": "価格",
  "TopNavBar--contact": "コンタクト",
  "TopNavBar--cta": "詳細を見る",

  // Header
  "Header--header-image-alt": "プログラミングを学習している男性",
  "Header--copy": "本気でJavaを学ぶなら、マネカワ。",
  "Header--copy-follower": "いまの会社は満足ですか。いまの働き方を何年続けられますか。いまのスクールは安心ですか。あらゆる安定が失われたこの時代に、あなた自身を変えるためのプログラミングスキルをつけましょう。元GAFAエンジニアが設立した「マネカワ」が、その手助けをいたします。",
  "Header--quote-1": "ITエンジニアとして「働き始めてからどう行動するか」の大切さを痛感しました。",
  "Header--quote-2": "今は駆け出しの身で大変ですが、日々幸せを感じる事ができています！",
  "Header--quote-name": "Ikeda, Shinnosuke",
  "Header--cta": "詳細を見る",


  // Services
  "Services--header": "マネカワが提供する最強のサービス。",
  "Services--header-follower-1": "社会で通用するスキルを身に着けるためには、単なるプログラミング教育では全く足りません。",
  "Services--header-follower-2": "元エンジニアが手掛けるマネカワは、「環境構築」「対人スキル(*2)」「バージョン管理」など、現場で本当に求められる技術を学ぶ機会を提供します。",
  "Services--programming-image-alt": "山積みにされた技術書",
  "Services--programming-title": "1. Javaプログラミング学習",
  "Services--programming-description": "プログラミングはJavaエンジニアの必須スキルです。マネカワのプログラミング学習サービスでは、基礎を徹底的に学び、未知の問題も乗り越えられる力を身に着けることができます。",
  "Services--softskill-image-alt": "議論するソフトウェアエンジニアたち",
  "Services--softskill-title": "2. 対人スキルトレーニング *2",
  "Services--softskill-description": "ただプログラミングが出来るだけでは、ITエンジニアとして働くことは難しいでしょう。「実践スキル学習」では、チームでプロダクトを開発する想定のもと、対人スキルの強化に焦点を当てた学習機会を提供します。*2",
  "Services--internship-image-alt": "握手するソフトウェアエンジニアとマネージャー",
  "Services--internship-title": "3. テック企業インターンシップ *3",
  "Services--internship-description": "現場で苦労しながら身につけたスキルは一生の力になります。マネカワはこの体験をパッケージ化し、最前線のテック企業・チームでのインターンシップを通じ、本当に必要なスキルを身に着ける機会を提供します。*3",


  // Needs
  "Needs--header": "こんなお悩み、ありませんか？",
  "Needs--experience": "「全くの未経験で、講座の前提が理解できずに挫折……」",
  "Needs--experience-image-alt": "PCの前で頭を抱える男性",
  "Needs--choice": "「技術書の数が多すぎて、どれだけ読めばいいのか……」",
  "Needs--choice-image-alt": "大量の本に囲まれて困っている女性",
  "Needs--afterskill": "「テック企業に入れた！ けど、なかなか評価されない……」",
  "Needs--afterskill-image-alt": "チームメイトの集団から離れて立っている男性",
  "Needs--footer": "そのお悩み、マネカワにお任せください。",

  // Pros
  "Pros--header": "マネカワだから実感できる「３つの安心」。",
  "Pros--experience-title": "完全未経験からサポート。だから安心。",
  "Pros--experience-image-alt": "経験者に親身に教えてもらっている男性",
  "Pros--experience-description": "マネカワでは完全未経験からの学習を念頭に置いています。手元でプログラムを実行できるところから、プロのエンジニアと一緒に働けるようになるまで、ステップバイステップで学ぶことが出来ます。",
  "Pros--choice-title": "外資系トップエンジニアが執筆。だから安心。",
  "Pros--choice-image-alt": "本を執筆している男性",
  "Pros--choice-description": "テキストは元GAFAのエンジニアが直接執筆。トッププロの現場で使われる、専門用語の用法やコードの書き方を、いますぐに習得できます。",
  "Pros--afterskill-title": "入社「してからの」スキルまで伝授(*2)。だから安心。",
  "Pros--afterskill-image-alt": "笑顔で面談している女性と若い女性",
  "Pros--afterskill-description": "就職・転職に成功したら、そこからがキャリア育成の本番。チームの信頼を獲得し、キャリアアップを成功させるところまで、マネカワが徹底的にサポートします。(*2)",

  //Profile
  "Profile--header": "わたしがあなたの成功を支えます。",
  "Profile--title": "マネカワ 代表",
  "Profile--name": "姉川将之",
  "Profile--image-alt": "マネカワ 代表の姉川将之",
  "Profile--history-header": "略歴",
  "Profile--history": "2016年、東京大学卒。求職検索サイト「Indeed」やAIスタートアップ「AVILEN」など、最先端のテックカンパニーで開発スキルを習得。2022年、アマゾンジャパン合同会社にエンジニアとしてジョイン。2023年、マネカワ創業。",


  // Projects
  "Projects--pre-header": "即戦力を目指すプログラミング教材 第一弾",
  "Projects--header": "「マネカワのJava」好評発売中。",
  "Projects--description-1": "「なぜ、いまさらJava？」と疑問に思う方もいらっしゃるかもしれません。しかしJavaは現在でも最も広く使われている言語であり、求人数が最も多い言語です。リスキリングを目指している方、または将来ソフトウェアエンジニアとして働く予定の学生の方には、まずJavaをしっかり学習することをおすすめします。",
  "Projects--details-header": "詳細情報",
  "Projects--details-1": "価格: ￥3,980（消費税込み）",
  "Projects--details-2": "形式: ダウンロード版（PDF）。PDFファイルの閲覧には、Adobe Reader等のPDFファイル閲覧ソフトが必要です。",
  "Projects--details-3": "再ダウンロード: 購入から1年以内、かつ3回以内に限り可能。詳細はお問い合わせ用メールアドレス(contact@manekawa.com)までご連絡ください。",
  "Projects--details-4": "ページ数: 125ページ",
  "Projects--details-5": "返品ポリシー: 購入から7日以内に限り返品可能。お問い合わせ用メールアドレス(contact@manekawa.com)までご連絡ください。",
  "Projects--details-6": "備考: 決済が完了すると、ダウンロードURLがメールで送信されます。メールに記載のURLは1週間で失効しますのでご注意ください。メールが届かない、もしくはURLが執行してしまった場合は、お問い合わせ用メールアドレス(contact@manekawa.com)までご連絡ください。",
  "Projects--sample-cta": "サンプルを見る",

  // Contact
  "Contact--header": "いますぐ先行登録して、10%オフクーポンをゲットしよう。",
  "Contact--description-1": "私達マネカワは現在、3月中の配信を目安にコンテンツを制作中です。皆様には今しばらくお待ちいただければと思います。",
  "Contact--description-2": "以下のフォームにメールアドレスを入力して送信いただくと、教材の10%オフクーポンが受け取れます。",
  "Contact--description-3": "教材の発売後はクーポンはお受け取りになれませんので、ぜひ今のうちに先行登録していただくことをおすすめいたします。",
  "Contact--email": "Eメールアドレス：",
  "Contact--email-invalid": "有効なEメールアドレスを入力してください。",
  "Contact--confirm-email": "Eメールアドレス(確認用)：",
  "Contact--email-confirm-not-match": "Eメールアドレスが一致しません。",
  "Contact--privacy-notice-pre": "次のボタンを押下することで、",
  "Contact--privacy-notice-post": "に同意したものとみなします。",
  "Contact--cta": "いますぐ登録してクーポンを受け取る",
  "Contact--thanks": "登録ありがとうございます。まもなくクーポンをお送りいたします。",

  // Thanks
  "Thanks--header": "登録ありがとうございます。",
  "Thanks--description": "まもなくクーポンをお送りいたします。今しばらくお待ちください。",

  // SampleThanks
  "SampleThanks--header": "サンプルのご請求ありがとうございます。",
  "SampleThanks--description": "まもなくサンプルのダウンロードが始まります。ブラウザーのダウンロードフォルダをご確認ください。",

  // Checkout
  "Checkout--header": "購入ありがとうございます。",
  "Checkout--description": "まもなくダウンロードリンクをお送りいたします。メールボックスをご確認ください。",
  "Checkout--cta": "購入へ進む",

  // Campaign
  "Campaign--header": "創業記念。いまだけ3つのスペシャルオファーを無料で獲得できます。",
  "Campaign--description": "マネカワは2023年2月に創業したばかり。まだまだ事業実績が足りていないのが本音です。それでも私達のサービスに興味を持っていただけたあなたに、お礼として3つのスペシャルオファーを無料で進呈いたします。2023年6月末までの期間限定ですので、ぜひお早めにご検討ください。",
  "Campaign--offer-1": "1. 元GAFAエンジニアとの個別ウェブ面談",
  "Campaign--offer-1-description": "元GAFAエンジニアにプログラミング・転職・キャリアアップの相談をするチャンス！個別ウェブ面談でご対応いたします(30分まで)。",
  "Campaign--offer-2": "2. 「マネカワのJava Web版」(2023年夏頃配信予定)1年分の利用権",
  "Campaign--offer-2-description": "2023年夏頃に配信予定の「マネカワのJava Web版」を1年間無料で利用できます。",
  "Campaign--offer-3": "3. 『マネカワのJava』の修正版・更新版を生涯無料で受ける権利",
  "Campaign--offer-3-description": "『マネカワのJava』は皆様のフィードバックを受け、修正版・改訂版を定期的に配信していく計画です。それらの修正版・更新版が配信される限り、その全てを無料で受け取る権利を今だけ獲得できます。",

  "Campaign--cta": "購入へ進む",

  // Footer
  "Footer--footnote-1": "*1 マネカワが今後提供を予定しているサービスを列挙したものです。「マネカワのJava」には含まれません。",
  "Footer--footnote-2": "*2 「対人スキルトレーニング」は2023年秋ごろ提供予定です。",
  "Footer--footnote-3": "*3 「テック企業インターンシップ」は2024年提供予定です。",

};

export default ja;