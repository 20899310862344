// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Needs from "../components/Sections/Needs";
import Profile from "../components/Sections/Profile";
import Projects from "../components/Sections/Projects";
import Pros from "../components/Sections/Pros";
import Campaign from "../components/Sections/Campaign";
import Footer from "../components/Sections/Footer"

export default function Landing() {
  return (
    <>
      <TopNavbar isLp={true} />
      <Header />
      <Campaign />
      <Services />
      <Needs />
      <Pros />
      <Profile />
      <Projects />
      <Campaign />
      <Footer isLp={true} />
    </>
  );
}

