import { useEffect } from "react";
const DOWNLOAD_SAMPLE_URL = process.env.REACT_APP_DOWNLOAD_SAMPLE_URL ?? 'https://manekawa-textbook-sample.s3.ap-northeast-1.amazonaws.com/manekawa-java-sample.pdf';

const useDownloadSampleEffect = () => {
  useEffect(() => {
    setTimeout(() => {
      downloadFile(DOWNLOAD_SAMPLE_URL, 'manekawa-java-sample.pdf');
    }, 2000);
  }, []);
}

const downloadFile = (url, fileName) => {
  fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}

export default useDownloadSampleEffect;