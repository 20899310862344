import styled from "styled-components";
import { useTranslation } from 'react-i18next';

// Components
import NeedBox from "../Elements/NeedBox";
// Assets
import ExperienceImage from "../../assets/img/needs/experience.jpg";
import ChoiceImage from "../../assets/img/needs/choice.jpg";
import AfterskillImage from "../../assets/img/needs/afterskill.jpg";

export default function Needs() {
  const { t } = useTranslation();

  return (
    <Wrapper id="services">
      <div className="lightBg py-16 flex flex-col items-center">
        <div className="container">
          <div className="mb-12">
            <h1 className="font40 extraBold">{t('Needs--header')}</h1>
          </div>
          <NeedBoxRow className="flex">
            <NeedBoxWrapper>
              <NeedBox
                image={ExperienceImage}
                imageAlt={t('Needs--experience-image-alt')}
                title={t('Needs--experience')}
              />
            </NeedBoxWrapper>
            <NeedBoxWrapper>
              <NeedBox
                image={ChoiceImage}
                imageAlt={t('Needs--choice-image-alt')}
                title={t('Needs--choice')}
              />
            </NeedBoxWrapper>
            <NeedBoxWrapper>
              <NeedBox
                image={AfterskillImage}
                imageAlt={t('Needs--afterskill-image-alt')}
                title={t('Needs--afterskill')}
              />
            </NeedBoxWrapper>
          </NeedBoxRow>
        </div>
        <div className="font-bold font40 p-4 text-center text-ltsBlue">
          {t('Needs--footer')}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
`;
const NeedBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  justify-content: space-around;
`;
const NeedBoxWrapper = styled.div`
  width: 25%;
  padding: 0;
  @media (max-width: 860px) {
    width: 100%;
    padding: 2rem 0;
  }
`;
