import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer"

const Privacy = () => {
  return (
    <>
      <TopNavbar />
      <div className="px-10 mb-10 max-w-3xl mx-auto">
        <h1 className="mt-24 mb-5 text-3xl max-sm:text-2xl text-center text-ltsBlue font-bold">
          プライバシーポリシー <br />（個人情報保護方針）
        </h1>

        <p className="py-5">
          姉川将之（以下、「当事業主」という。）は，ユーザーの個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当事業者がどのような個人情報を取得し、どのように利用・共有するか、ユーザーがどのようにご自身の個人情報を管理できるかをご説明するものです。
        </p>

        <h2 className="text-2xl py-3">１．事業者情報</h2>
        <p className="py-5">
          屋号：LearnToSurvive
          <br />
          住所：東京都豊島区高田２丁目１６番１３号
          <br />
          事業者：姉川将之
        </p>
        <h2 className="text-2xl py-3">２．個人情報の取得方法</h2>
        <p className="py-5">
          当事業者はユーザーが利用登録をするとき、氏名・生年月日・住所・電話番号・メールアドレスなど個人を特定できる情報を取得させていただきます。
          お問い合わせフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。
        </p>
        <h2 className="text-2xl py-3">３．個人情報の利用目的</h2>
        <p className="py-5">
          取得した閲覧・購買履歴等の情報を分析し、ユーザー別に適した商品・サービスをお知らせするために利用します。
        </p>
        <h2 className="text-2xl py-3">４．個人データを安全に管理するための措置</h2>
        <p className="py-5">
          当事業者は個人情報を正確かつ最新の内容に保つよう努め、不正なアクセス・改ざん・漏えい・滅失及び毀損から保護するため研修を実施しています。
        </p>
        <h2 className="text-2xl py-3">５．個人データの共同利用</h2>
        <p className="py-5">
          当事業者は個人データの共同利用を行いません。
        </p>
        <h2 className="text-2xl py-3">６．個人データの第三者提供について</h2>
        <p className="py-5">
          当事業者は法令及びガイドラインに別段の定めがある場合を除き、同意を得ないで第三者に個人情報を提供することは致しません。
        </p>
        <h2 className="text-2xl py-3">７．保有個人データの開示、訂正</h2>
        <p className="py-5">
          当事業者は本人から個人情報の開示を求められたときには、遅滞なく本人に対しこれを開示します。個人情報の利用目的の通知や訂正、追加、削除、利用の停止、第三者への提供の停止を希望される方は以下の手順でご請求ください。
          <ul className="list-[square] p-4">
            <li>
              Eメールにて、以下に示す宛先に、「保有個人情報開示請求」「保有個人情報削除請求」などと、要求内容を明記したメールを送る。
            </li>
          </ul>
          Eメール送付先： contact@learntosurvive.dev
        </p>
        <h2 className="text-2xl py-3">８．個人情報取り扱いに関する相談や苦情の連絡先</h2>
        <p className="py-5">
          当事業者の個人情報の取り扱いに関するご質問やご不明点、苦情、その他のお問い合わせはEメール(送付先: contact@learntosurvive.dev)よりご連絡ください。
        </p>
        <h2 className="text-2xl py-3">９．SSL（Secure Socket Layer）について</h2>
        <p className="py-5">
          当事業者のWebサイトはSSLに対応しており、WebブラウザとWebサーバーとの通信を暗号化しています。ユーザーが入力する氏名や住所、電話番号などの個人情報は自動的に暗号化されます。
        </p>
        <h2 className="text-2xl py-3">１０．cookieについて</h2>
        <p className="py-5">
          cookieとは、WebサーバーからWebブラウザに送信されるデータのことです。Webサーバーがcookieを参照することでユーザーのパソコンを識別でき、効率的に当事業者Webサイトを利用することができます。当事業者Webサイトがcookieとして送るファイルは、個人を特定するような情報は含んでおりません。
          お使いのWebブラウザの設定により、cookieを無効にすることも可能です。
        </p>
        <h2 className="text-2xl py-3">１１．プライバシーポリシーの制定日及び改定日</h2>
        <p className="py-5">
          制定：2023年2月20日
        </p>
        <h2 className="text-2xl py-3">１２．免責事項</h2>
        <p className="py-5">
          当事業者Webサイトに掲載されている情報の正確性には万全を期していますが、利用者が当事業者Webサイトの情報を用いて行う一切の行為に関して、一切の責任を負わないものとします。
          当事業者は、利用者が当事業者Webサイトを利用したことにより生じた利用者の損害及び利用者が第三者に与えた損害に関して、一切の責任を負わないものとします。
        </p>
        <h2 className="text-2xl py-3">１３．著作権・肖像権</h2>
        <p className="py-5">
          当事業者Webサイト内の文章や画像、すべてのコンテンツは著作権・肖像権等により保護されています。無断での使用や転用は禁止されています。
        </p>
        <h2 className="text-2xl py-3">１４．リンク</h2>
        <p className="py-5">
          当事業者Webサイトへのリンクは、自由に設置していただいて構いません。ただし、Webサイトの内容等によってはリンクの設置をお断りすることがあります。
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
