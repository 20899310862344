import { useCallback } from "react";
const CREATE_CHECKOUT_URL = process.env.REACT_APP_CREATE_CHECKOUT_URL ?? '';

const useCheckoutHandler = () => {
  const handleCheckout = useCallback(() => {
    fetch(CREATE_CHECKOUT_URL, {
      method: 'POST',
    }).then((response) => response.json()
    ).then((responseJson) => {
      console.log(responseJson.url)
      window.location = responseJson.url;
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return handleCheckout;
}

export default useCheckoutHandler;