import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
// Screens
import Checkout from "./screens/Checkout";
import Landing from "./screens/Landing";
import Thanks from "./screens/Thanks";
import SampleThanks from "./screens/SampleThanks";
import Spca from "./screens/Spca";
import Privacy from "./screens/Privacy";
import ScrollToTop from "./components/Nav/ScrollToTop";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/sample-thanks" element={<SampleThanks />} />
          <Route path="/spca" element={<Spca />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>
      </Router>
    </>
  );
}

