import { useTranslation } from 'react-i18next';
import QuotesIcon from "../../assets/svg/Quotes";

const HeaderQuote = ({ className, quoteKey1, quoteKey2, quoteNameKey }) => {
  const { t } = useTranslation();

  return <div className={`${className ?? ""}  bg-ltsBlue rounded-lg max-w-md p-8`}>
    <div className="absolute -left-5 -top-2">
      <QuotesIcon />
    </div>
    <div>
      <p>
        <em className="font15 whiteColor font-semibold">{t(quoteKey1)} <br /> {t(quoteKey2)}</em>
      </p>
      <p className="font13 orangeColor textRight font-semibold" style={{ marginTop: '10px' }}>{t(quoteNameKey)}</p>
    </div>
  </div>;
};

export default HeaderQuote;
