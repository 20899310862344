import styled from "styled-components";

export default function NeedBox({image, imageAlt, title}) {
  return (
    <Wrapper className="flexNullCenter flexColumn">
      <img className="radius8 w-full sm:max-w-[50vw]" src={image} alt={imageAlt} />
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;
  padding: 4rem 0;
  @media (max-width: 860px) {
    padding: 1rem 0;
  }
`;