import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { Link } from "react-scroll";

// Components
import HeaderQuote from "../Elements/HeaderQuote";
// Assets
import HeaderImage from "../../assets/img/header-image.png";
import Dots from "../../assets/svg/Dots";

export default function Header() {
  const { t } = useTranslation();

  return (
    <>
      <Wrapper id="home" className="container flexSpaceCenter">
        <LeftSide className="flexCenter">
          <div>
            <h1 className="extraBold font60 blueColor">{t('Header--copy')}</h1>
            <HeaderP className="font13 semiBold">
              {t('Header--copy-follower')}
            </HeaderP>
            <div className="max-w-sm mx-auto ">
              <Link to="projects" smooth={true} offset={-130}>
                <ButtonInput type="button" value={t('Header--cta')} className="pointer animate radius8 font15 font-bold" />
              </Link>
            </div>
          </div>
        </LeftSide>
        <RightSide>
          <ImageWrapper>
            <Img className="radius8 z-10" src={HeaderImage} alt={t('Header--header-image-alt')} />
            <HeaderQuote
              quoteKey1={'Header--quote-1'}
              quoteKey2={'Header--quote-2'}
              quoteNameKey={'Header--quote-name'}
              className="absolute left-0 bottom-12 z-50 max-lg:left-16 max-sm:-bottom-12"/>
            <DotsWrapper>
              <Dots />
            </DotsWrapper>
          </ImageWrapper>
          <div className="whiteBg"></div>
        </RightSide>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    h1 { text-align: center; }
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 0;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    max-width: 100%;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
const ButtonInput = styled.input`
  width: 80%;
  border: 1px solid ${props => props.theme.colors.main};
  background-color: ${props => props.theme.colors.main};
  padding: 1rem;
  outline: none;
  color: #fff;
  overflow-wrap: break-word;
  :hover {
    background-color: ${props => props.theme.colors.mainLight};
    border: 1px solid ${props => props.theme.colors.mainLight};
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
    height: 4rem;
    width: 100%;
  }
`;
