const en = {
    // General
    "General--company-name": "Manekawa",
    "General--cta": "Sign up now",

    // TopNavBar
    "TopNavBar--home": "Home",
    "TopNavBar--services": "Services",
    "TopNavBar--projects": "Projects",
    "TopNavBar--blog": "Blog",
    "TopNavBar--pricing": "Pricing",
    "TopNavBar--contact": "Contact",

    // Header
    "Header--header-image-alt": "A man learning programming",
    "Header--copy": "Learn. To. Survive.",
    "Header--copy-follower": "We are currently in a tumultuous time where there is no longer a safe haven in companies or countries. Manekawa, founded by a former GAFA engineer, will help you acquire the skills necessary to survive in any situation by providing training in technologies that are actually required in the field, such as environment construction, soft skills, and version management.",
    "Header--quote": "I felt limited in my sales job and made a career change to become an engineer. I realized that it was the best decision I ever made!",
    "Header--quote-name": "Shinnosuke Ikeda",

    // Services
    "Services--header": "The strongest services, by Manekawa.",
    "Services--header-follower-1": "Learning programming alone is not enough to acquire the skills necessary to survive in society.",
    "Services--header-follower-2": "Manekawa, run by a former GAFA engineer, provides training in the technologies actually required in the field, such as environment construction, soft skills, and version management.",
    "Services--programming-title": "Programming Learning",
    "Services--programming-description": "Being able to code programs at a high level is a prerequisite for being a software engineer. Manekawa's programming learning course thoroughly teaches the basics and helps you acquire the ability to overcome unknown problems.",
    "Services--softskill-title": "Soft Skill Training",
    "Services--softskill-description": "Being able to program is not enough to work as a software engineer. In practical skills learning, we focus on strengthening soft skills by assuming development in a team.",
    "Services--internship-title": "Tech Company Internship",
    "Services--internship-description": "Many soft skills are learned through experience in the field. Manekawa packages this experience and provides opportunities to acquire the necessary skills by interning with frontline tech companies and teams.",
    "Services--multimedia-pre-header": 'Covering web services, podcasts, and video services',
    "Services--multimedia-header": 'Can’t keep up with your studies? Why not "study on the go" on YouTube?',
    "Services--multimedia-description": 'Studying with just text may not be suitable for everyone and can lead to giving up. When that happens, take a break from textbooks and try watching a variety of media. Manekawa provides learning content through various media such as Twitter, podcasts, and YouTube.',

    // Products
    "Projects--pre-header": "The first programming education material for immediate use",
    "Projects--header": "\"Learning Java to Survive\" coming soon.",
    "Projects--description-1": "Some of you may wonder, 'Why Java now?' and 'Why not teach \"cool\" languages like Go or Rust?' However, Java is still the most widely used language and has the highest number of job opportunities. For those aiming to sharpening skills or students planning to work as software engineers in the future, we recommend starting by learning Java thoroughly.",
    "Projects--description-2": "If you are interested, please sign up in the form below to get a 10% off coupon. The coupon cannot be obtained after the release.",

    // Contact
    "Contact--header": "Sign up now and get a 10% off coupon.",
    "Contact--description-1": "We at Manekawa are currently working hard to produce content. We appreciate your patience.",
    "Contact--description-2": "If you enter your email address in the form below and send it, a 10% off coupon for the material will be sent to you.",
    "Contact--description-3": "We recommend signing up now for early access, as the coupon cannot be received after the material is released.",
    "Contact--email": "Email address:",
    "Contact--confirm-email": "Confirm email address:",
};

export default en;