import styled from "styled-components";
import { useTranslation } from 'react-i18next';

// Components
import ServiceBox from "../Elements/ServiceBox";
// Assets
import ExperienceImage from "../../assets/img/pros/experience.jpg";
import ChoiceImage from "../../assets/img/pros/choice.jpg";
import AfterskillImage from "../../assets/img/pros/afterskill.jpg";

export default function Pros() {
  const { t } = useTranslation();

  return (
    <Wrapper id="services">
      <div className="whiteBg pt-16 mb-8  items-center">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">{t('Pros--header')}</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                image={ExperienceImage}
                imageAlt={t('Pros--experience-image-alt')}
                title={t('Pros--experience-title')}
                description={t('Pros--experience-description')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={ChoiceImage}
                imageAlt={t('Pros--choice-image-alt')}
                title={t('Pros--choice-title')}
                description={t('Pros--choice-description')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={AfterskillImage}
                imageAlt={t('Pros--afterskill-image-alt')}
                title={t('Pros--afterskill-title')}
                description={t('Pros--afterskill-description')}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  justify-content: space-around;
`;
const ServiceBoxWrapper = styled.div`
  width: 25%;
  padding: 5rem 0;
  @media (max-width: 860px) {
    width: 100%;
    padding: 2.5rem 0;
  }
`;
const HeaderInfo = styled.div`
  p {
    padding: 0 1rem;
  }
`;
