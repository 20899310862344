const theme = {
  colors: {
    main: '#3441ef',
    mainLight: '#646df3',
    accent: '#F28B02',
    white: '#fafaff',
    black: '#0e0107',
    gray: '#92818d',
  },
};

export default theme;