import styled from "styled-components";
import { useTranslation } from 'react-i18next';

// Components
import ServiceBox from "../Elements/ServiceBox";
// Assets
import ProgrammingImage from "../../assets/img/services/programming.png";
import SoftSkillImage from "../../assets/img/services/softskill.png";
import InternshipImage from "../../assets/img/services/internship.png";

export default function Services() {
  const { t } = useTranslation();

  return (
    <Wrapper id="services">
      <div className="whiteBg py-16  items-center">
        <div className="container">
          <div className="lg:px-10">
            <h1 className="font40 extraBold mb-4">{t('Services--header')}</h1>
            <p className="font13 px-4">
              {t('Services--header-follower-1')}
              <br />
              {t('Services--header-follower-2')}
            </p>
          </div>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                image={ProgrammingImage}
                imageAlt={t('Services--programming-image-alt')}
                title={t('Services--programming-title')}
                description={t('Services--programming-description')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={SoftSkillImage}
                imageAlt={t('Services--softskill-image-alt')}
                title={t('Services--softskill-title')}
                description={t('Services--softskill-description')}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                image={InternshipImage}
                imageAlt={t('Services--internship-image-alt')}
                title={t('Services--internship-title')}
                description={t('Services--internship-description')}
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
  justify-content: space-around;
`;
const ServiceBoxWrapper = styled.div`
  width: 25%;
  padding: 3rem 0;
  @media (max-width: 860px) {
    width: 100%;
    padding: 2.5rem 0;
  }
`;
